<template>
  <div class="page-footer">
    <div class="footer-top">
      <div><img src="~@/assets/images/logo_f.svg" alt=""></div>
      <div>
        <img @click="goExternalLinks('https://www.linkedin.com/company/102740042/')" src="~@/assets/images/f_ins_active.png" alt="">
        <img @click="goExternalLinks('https://www.facebook.com/profile.php?id=61560288763455')" src="~@/assets/images/f_facebook_active.png" alt="">
        <img src="~@/assets/images/f_X.png" alt="">
      </div>
    </div>
    <div class="footer-bot">
      <div class="footer-bot-l">
        <p>取材、プレスリリース、レビュー等に関するお問い合わせ:&emsp;<a @click="sendEmail">soe.contact@dolphin-ai.jp</a></p>
        <div>
          <strong @click="openMD('ServiceAgreement')"><a>利用規約</a></strong>
          <p>|</p>
          <strong @click="openMD('PrivacyPolicy')"><a>プライバシー</a></strong>
        </div>
        <p>Copyright © 2024 <a href="https://dolphin-ai.jp" target="_blank">Dolphin-AI.JP</a> Corporation</p>
      </div>
      <div class="footer-bot-r">
        <p v-if="!isSmallScreen">Address</p>
        <p v-if="isSmallScreen">Addr：</p>
        <p>〒170-0013</p>
        <p>東京都豊島区東池袋1-18-1<br>ハレザタワー20階</p>
        <div class="footer-bot-security">
          <p><img src="@/assets/images/security1.png" alt=""><span>ISO 27001</span></p>
          <p><img src="@/assets/images/security2.svg" alt=""><span>SSL/TLS</span></p>
          <p><img src="@/assets/images/security3.svg" alt=""><span>GDPR</span></p>
          <p><img src="@/assets/images/security4.svg" alt=""><span>APPI</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-footer',
  data () {
    return {
      isSmallScreen: window.innerWidth <= 768,
    }
  },
  methods: {
    sendEmail () {
      window.open('mailto:soe.contact@dolphin-ai.jp','_blank')
    },
    openMD (url) {
      if (url === 'ServiceAgreement') {
        this.$router.push({
          path: '/service-agreement'
        }).catch(() => {})
      } else {
        this.$router.push({
          path: '/privacy-policy'
        }).catch(() => {})
      }
    },
    goExternalLinks (url) {
      window.open(url)
    },
  }
}
</script>

<style lang="less" scoped>
.page-footer {
  min-height: 300px;
  background: rgba(2, 8, 45, 1);
  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 108px;
    padding-left: 200px;
    border-bottom: 1px solid rgba(60, 65, 99, 1);
    div:first-child {
      img {
        height: 44px;
      }
    }
    div:last-child {
      width: 440px;
      padding-left: 40px;
      text-align: left;
      img {
        width: 32px;
        height: 32px;
        margin-right: 24px;
      }
      img:not(:nth-last-of-type(1)) {
        cursor: pointer;
      }
    }
  }
  .footer-bot {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 240px;
    padding-left: 200px;
    color: rgba(242, 242, 243, 1);
    >div {
      height: 100%;
    }
    .footer-bot-l {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;
      margin-top: 40px;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: rgba(242, 242, 243, 1);
      div {
        display: flex;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 24px;
        cursor: pointer;
        cursor: hand;
        strong {
          color: #1890ff;
          a {
            color: #1890ff;
          }
        }
        p {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      >p {
        a {
          color: rgba(24, 144, 255, 1);
          cursor: pointer;
        }
        >span {
          cursor: pointer;
          cursor: hand;
        }
      }
    }
    .footer-bot-r {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 440px;
      padding-left: 40px;
      padding-top: 40px;
      padding-bottom: 40px;
      border-left: 1px solid rgba(60, 65, 99, 1);
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: rgba(242, 242, 243, 1);
      >p {
        text-align: left;
      }
      >p:first-child {
        margin-bottom: 20px;
        font-weight: 700;
      }
    }
    .footer-bot-security {
      display: flex;
      margin-top: 15px;
      p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;
        span {
          font-size: 12px;
        }
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .page-footer {
    min-height: 100px;
    padding-bottom: 21px;
  }
  .page-footer .footer-top {
    padding-left: 24px;
    padding-right: 24px;
  }
  .page-footer .footer-top {
    height: 48px;
  }
  .page-footer .footer-bot {
    min-height: 80px;
    height: auto;
    flex-direction: column;
  }
  .page-footer .footer-top div:first-child, .page-footer .footer-top div:first-child img {
    //width: 117px;
    height: 28px;
  }
  .page-footer .footer-top div:last-child {
    height: 24px;
    width: fit-content;
  }
  .page-footer .footer-top div:last-child img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  .page-footer .footer-bot {
    padding-left: 0;
    padding-right: 0;
  }
  .page-footer .footer-top div:last-child img:nth-last-of-type(1) {
    margin-right: 0;
  }
  .page-footer .footer-bot .footer-bot-l {
    border-right: 0;
    border-bottom: 1px solid rgba(38, 43, 79, 1);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .page-footer .footer-bot .footer-bot-l >p:nth-of-type(1) {
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px;
  }
  .page-footer .footer-bot .footer-bot-l >p:nth-of-type(1) >span {
    font-size: 12px;
    line-height: 17px;
    color: rgba(24, 144, 255, 1);
    padding-top: 8px;
    text-decoration: underline;
  }
  .page-footer .footer-bot .footer-bot-l > p a {
    text-decoration: underline;
  }
  .page-footer .footer-bot .footer-bot-l div p {
    display: none;
  }
  .page-footer .footer-bot .footer-bot-l div {
    justify-content: center;
    margin: 8px auto;
    font-size: 12px;
    line-height: 17px;
    >strong {
      color: #1890ff;
      text-decoration: underline;
      a {
        color: #1890ff;
      }
    }
    >strong:nth-of-type(1) {
      margin-right: 48px;
    }
  }
  .page-footer .footer-bot .footer-bot-l >p:nth-of-type(2) {
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
  }
  .page-footer .footer-bot .footer-bot-r {
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .page-footer .footer-bot .footer-bot-r>p {
    margin: 0!important;
    font-size: 12px;
    line-height: 18px;
    display: inline;
    >br {
      display: none;
    }
  }
  .page-footer .footer-bot .footer-bot-r .footer-bot-security {
    display: flex;
    margin-top: 15px;
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 15px 0;
      span {
        font-size: 12px;
      }
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
</style>

<template>
  <div id="app" v-title data-title="Professional Pronunciation Assessment API | Dolphin AI">
    <HeaderVue v-if="Loaded"></HeaderVue>
    <router-view v-if="Loaded" />
    <FooterVue v-if="Loaded"></FooterVue>
  </div>
</template>

<script>
import HeaderVue from './components/Header.vue'
import FooterVue from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    HeaderVue,
    FooterVue
  },
  data () {
    return {
      Loaded: false,
    }
  },
  watch: {
    '$route'(to, from) {
      console.log('Route changed from', from.path, 'to', to.path);
      this.Loaded = true
    }
  },
}
</script>

<style lang="less">
#app {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}
</style>

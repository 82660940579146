<template>
  <div>
    <div id="head-page" v-if="!isSmallScreen">
      <div class="logo" @click="$router.push('/')">
        <img src="~@/assets/images/logo.svg" alt="">
      </div>
      <el-menu
        :default-active="activeIndex"
        router
        class="el-menu"
        mode="horizontal"
        text-color="rgba(3, 8, 82, 1)"
        active-text-color="rgba(3, 8, 82, 1)">
        <el-menu-item index="/">トップページ</el-menu-item>
        <el-menu-item index="/demo/english/word-evaluation">オンラインお試し</el-menu-item>
        <el-menu-item index="/pricing">価格</el-menu-item>
        <el-submenu class="entxt" index="/feature" :popper-append-to-body="false">
          <template slot="title">プロダクト</template>
          <el-menu-item index="/feature">特徴</el-menu-item>
          <el-menu-item index="/soe">コア技術</el-menu-item>
        </el-submenu>
<!--        <el-menu-item index="/help?url=start.md">ドキュメント</el-menu-item>-->
        <el-menu-item index="" @click="goBlog">ブログ</el-menu-item>
      </el-menu>
      <div class="operation">
<!--        <el-button class="border-btn" plain @click="goBlog">ブログ</el-button>-->
        <el-tooltip popper-class="headertip" class="item" effect="dark" content="ドキュメント" placement="bottom">
          <div @click="$router.push('/help?url=start.md')" @mouseenter="pageDoc=true" @mouseleave="$route.path.indexOf('/help') !== -1?pageDoc=true:pageDoc=false" :class="{active:pageDoc}">
            <img v-if="!pageDoc" src="~@/assets/images/doc.png" alt="">
            <img v-if="pageDoc" src="~@/assets/images/doc_active.png" alt="">
          </div>
        </el-tooltip>
        <el-tooltip popper-class="headertip" class="item" effect="dark" content="お問い合わせ" placement="bottom">
          <div @click="$router.push('/contactus')" @mouseenter="pageContact=true" @mouseleave="$route.path.indexOf('/contactus') !== -1?pageContact=true:pageContact=false" :class="{active:pageContact}">
            <img v-if="!pageContact" src="~@/assets/images/contact.png" alt="">
            <img v-if="pageContact" src="~@/assets/images/contact_active.png" alt="">
          </div>
        </el-tooltip>
<!--        <el-button class="border-btn" plain @click="$router.push('/help?url=start.md')">ドキュメント</el-button>-->
<!--        <el-button class="bg-btn" type="primary" icon="el-icon-message" @click="$router.push('/contactus')">お問い合わせ</el-button>-->
        <el-button class="bg-btn" type="primary" @click="goLogin">ログイン</el-button>
      </div>
    </div>
    <div id="head-page-mobile" v-if="isSmallScreen">
      <div class="menu" @click="drawer=!drawer">
        <img src="~@/assets/images/index/menu.png" alt="">
      </div>
      <div class="logo" @click="$router.push('/');drawer=false">
        <img src="~@/assets/images/logo.svg" alt="">
      </div>
      <div class="email">
<!--        <i class="el-icon-message" @click="goBlog"></i>-->
        <img v-if="!pageDoc" @click="$router.push('/help?url=start.md');drawer=false" src="~@/assets/images/doc.png" alt="">
        <img v-if="pageDoc" @click="$router.push('/help?url=start.md');drawer=false" src="~@/assets/images/doc_active.png" alt="">
        <img v-if="!pageContact" @click="$router.push('/contactus');drawer=false" src="~@/assets/images/contact.png" alt="">
        <img v-if="pageContact" @click="$router.push('/contactus');drawer=false" src="~@/assets/images/contact_active.png" alt="">
<!--        <i class="el-icon-document" @click="$router.push('/help?url=start.md');drawer=false"></i>-->
<!--        <i class="el-icon-message" @click="$router.push('/contactus');drawer=false"></i>-->
      </div>
      <el-drawer
        custom-class="asideMenu"
        size="100%"
        :modal="true"
        :withHeader="false"
        :modal-append-to-body="false"
        :append-to-body="false"
        :visible.sync="drawer"
        :direction="direction"
        @open="handleOpen"
        @close="handleClose">
        <el-menu
          ref="navMenu"
          :default-active="activeIndex"
          router
          class="el-menu"
          text-color="rgba(3, 8, 82, 1)"
          active-text-color="rgba(3, 8, 82, 1)"
          @select="closeDrawer"
          @open="openMenu"
          @close="closeMenu"
          :unique-opened="true">
          <el-menu-item index="/">
            <span>トップページ</span>
            <img src="~@/assets/images/index/menu_right.png" alt="">
          </el-menu-item>
          <el-menu-item index="/demo/english/word-evaluation">
            <span>オンラインお試し</span>
            <img src="~@/assets/images/index/menu_right.png" alt="">
          </el-menu-item>
          <el-menu-item index="/pricing">
            <span>価格</span>
            <img src="~@/assets/images/index/menu_right.png" alt="">
          </el-menu-item>
          <el-submenu class="entxt" index="/feature" :popper-append-to-body="false">
            <template slot="title">
              <span>プロダクト</span>
              <img id="firstImg" class="myImg" src="~@/assets/images/index/menu_right.png" alt="">
            </template>
            <el-menu-item index="/feature">
              <span>特徴</span>
              <img src="~@/assets/images/index/menu_right.png" alt="">
            </el-menu-item>
            <el-menu-item index="/soe">
              <span>コア技術</span>
              <img src="~@/assets/images/index/menu_right.png" alt="">
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="" @click="goBlog">
            <span>ブログ</span>
            <img src="~@/assets/images/index/menu_right.png" alt="">
          </el-menu-item>
<!--          <el-menu-item index="/help?url=start.md">-->
<!--            <span>ドキュメント</span>-->
<!--            <img src="~@/assets/images/index/menu_right.png" alt="">-->
<!--          </el-menu-item>-->
        </el-menu>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'head-page',
  data () {
    return {
      isSmallScreen: window.innerWidth <= 768,
      activeIndex: this.$route.path,
      activeOpen: [],
      drawer: false,
      direction: 'ltr',

      pageDoc: false,
      pageContact: false,
    }
  },
  watch: {
    $route(to) {
      if (to.path.indexOf("/demo/english/word-evaluation") !== -1) {
        this.activeIndex = "/demo/english/word-evaluation"
      } else {
        this.activeIndex = to.path
      }
      this.pageDoc = to.path.indexOf("/help") !== -1;
      this.pageContact = to.path.indexOf("/contactus") !== -1;
    }
  },
  mounted() {
    if (this.$route.path.indexOf("/demo/english/word-evaluation") !== -1) {
      this.activeIndex = "/demo/english/word-evaluation"
    } else {
      this.activeIndex = this.$route.path
    }
    this.pageDoc = this.$route.path.indexOf("/help") !== -1;
    this.pageContact = this.$route.path.indexOf("/contactus") !== -1;
  },
  methods: {
    goLogin() {
      window.open('https://analytics.soe.dolphin-ai.jp/')
    },
    goBlog() {
      // window.open('https://blog.soe.dolphin-ai.jp/')
      window.location.href = 'https://blog.soe.dolphin-ai.jp/';
    },
    closeDrawer(index, indexPath) {
      if (index !== '/feature') {
        this.$refs.navMenu.close('/feature');
        let img = document.getElementById('firstImg');
        img.style.transform = 'rotate(0deg)';
        this.activeIndex = indexPath[indexPath.length - 1];
      }
      this.drawer = false
    },
    openMenu() {
      let img = document.getElementById('firstImg');
      img.style.transform = 'rotate(90deg)';
    },
    closeMenu() {
      let img = document.getElementById('firstImg');
      img.style.transform = 'rotate(0deg)';
    },
    handleOpen() {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    },
    handleClose() {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    },
  }
}
</script>

<style lang="less" scoped>
#head-page {
  position: relative;
  z-index: 1501;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 200px;
  box-shadow: -4px 4px 40px  rgba(8, 4, 71, 0.06);
  .logo {
    text-align: left;
    cursor: pointer;
    img {
      height: 40px;
    }
  }
  .el-menu {
    height: 100%;
    border: none;
    li {
      position: relative;
      height: 100%;
      line-height: 80px;
      padding: 0 32px;
      border: none;
      font-size: 18px;
    }
    .entxt {
      /deep/ .el-submenu__title {
        min-width: 88px;
        height: 100%;
        line-height: 80px;
        padding: 0;
        font-size: 18px;
        font-weight: 300;
        border: none!important;
      }
      /deep/ .el-submenu__title:hover {
        font-weight: 700;
      }
    }
    li:hover {
      font-family: Noto Sans JP;
      font-style: normal;
      font-weight: 700;
    }
    //li.entxt:hover {
    //  font-family: Public Sans;
    //  font-style: normal;
    //  font-weight: 700;
    //}
  }
  /deep/ .el-menu--horizontal {
    left: -13px!important;
  }
  /deep/ .el-menu--horizontal .el-menu-item {
    min-width: 132px;
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 300;
  }
  ///deep/ .el-menu--horizontal>.entxt {
  //  font-family: Public Sans;
  //  font-style: normal;
  //  font-weight: 300;
  //}
  /deep/ .el-menu--horizontal .el-menu-item.is-active {
    font-family: Noto Sans JP;
    font-style: normal;
    font-weight: 700;
    background: rgba(249, 250, 253, 1);
  }
  /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
    background: initial;
  }
  /deep/ .el-menu--horizontal .el-submenu.is-active {
    background: rgba(249, 250, 253, 1);
    .el-submenu__title {
      font-weight: 700;
    }
  }
  ///deep/ .el-menu--horizontal>.entxt.is-active {
  //  font-family: Public Sans;
  //  font-style: normal;
  //  font-weight: 700;
  //}
  .operation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin-right: 28px;
      border-radius: 8px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
      }
    }
    div.active {
      background: rgba(249, 250, 253, 1);
    }
    div:hover {
      background: rgba(249, 250, 253, 1);
    }
  }
  .border-btn {
    height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    border-radius: 8px;
    border: 2px solid rgba(24, 144, 255, 1);
    color: rgba(3, 8, 82, 1);
    font-size: 18px;
  }
  .bg-btn {
    height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 0 0 20px;
    background: rgba(24, 144, 255, 1);
    border-radius: 8px;
    font-size: 18px;
    border: none;
  }
}
#head-page-mobile {
  position: relative;
  z-index: 2005;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 24px;
  background: rgba(255, 255, 255, 1);
  box-shadow: -4px 4px 40px  rgba(8, 4, 71, 0.06);
  border-bottom: 1px solid rgba(233, 236, 245, 1);
  .menu {
    z-index: 2005;
    width: 58px;
    text-align: left;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .logo {
    z-index: 2005;
    img {
      height: 28px;
    }
  }
  .email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2005;
    width: 58px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    img:last-child {
      margin-right: 0;
    }
    i {
      font-size: 26px;
      color: #000;
    }
    i:first-child {
      font-size: 22px;
    }
  }
  /deep/ .el-drawer__wrapper {
    top: 49px!important;
  }
}
</style>
<style lang="less">
.asideMenu {
  .el-menu {
    width: 100%;
    font-size: 16px;
    color: rgba(3, 8, 82, 1);
    .el-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px!important;
      border-bottom: 1px solid rgba(233, 236, 245, 1);
      img {
        width: 24px;
        height: 24px;
      }
    }
    .el-menu-item.is-active {
      font-weight: 700;
      background: rgba(249, 250, 253, 1);
    }
    .entxt {
      border-bottom: 1px solid rgba(233, 236, 245, 1);
      .el-submenu__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px!important;
        font-size: 14px;
        text-align: left;
        img {
          width: 24px;
          height: 24px;
        }
        img.myImg {
          transition: transform 0.5s;
          transform: rotate(0);
        }
        i {
          display: none;
        }
      }
      .el-menu {
        .el-menu-item {
          border-bottom: none;
          padding: 0 40px!important;
        }
      }
    }
  }
}
#head-page-mobile {
  .el-drawer__wrapper {
    z-index: 2002!important;
  }
  .v-modal {
    z-index: 2001!important;
    background: transparent;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

let routes
if(window.innerWidth > 768) {
  routes = [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeView.vue"),
    },
    {
      path: "/demo/english/word-evaluation",
      name: "demo",
      component: () => import("../views/Demo.vue"),
      children: [
        {
          path: '/demo/english/phoneme-evaluation',
          name: 'SoeDemo_phoneme',
          component: () => import('@/components/demo/SoeDemo_Phoneme.vue')
        },
        {
          path: '/demo/english/word-evaluation',
          name: 'SoeDemo_word',
          component: () => import('../components/demo/SoeDemo_Word.vue'),
        },
        {
          path: '/demo/english/sentence-evaluation',
          name: 'SoeDemo_sentence',
          component: () => import('../components/demo/SoeDemo_Sentence.vue'),
        },
        {
          path: '/demo/english/paragraph-evaluation',
          name: 'SoeDemo_paragraph',
          component: () => import('../components/demo/SoeDemo_Paragraph.vue'),
        },
        {
          path: '/demo/english/word-mistake-evaluation',
          name: 'SoeDemo_wordmistake',
          component: () => import('../components/demo/SoeDemo_WordMistake.vue'),
        },
        {
          path: '/demo/english/sentence-mistake-evaluation',
          name: 'SoeDemo_sentencemistake',
          component: () => import('../components/demo/SoeDemo_SentenceMistake.vue'),
        },
        {
          path: '/demo/english/oral-questions-evaluation',
          name: 'SoeDemo_oralquestions',
          component: () => import('../components/demo/SoeDemo_OralQuestions.vue'),
        },
        {
          path: '/demo/english/story-evaluation',
          name: 'SoeDemo_story',
          component: () => import('../components/demo/SoeDemo_Story.vue'),
        },
        {
          path: '/demo/english/oral-composition-evaluation',
          name: 'SoeDemo_oralcomposition',
          component: () => import('../components/demo/SoeDemo_OralComposition.vue'),
        },
        {
          path: '/demo/english/recite-evaluation',
          name: 'SoeDemo_recite',
          component: () => import('../components/demo/SoeDemo_Recite.vue'),
        },
        {
          path: '/demo/english/choice-evaluation',
          name: 'SoeDemo_choise',
          component: () => import('../components/demo/SoeDemo_Choise.vue'),
        },
        {
          path: '/demo/english/elective-evaluation',
          name: 'SoeDemo_freechoise',
          component: () => import('../components/demo/SoeDemo_FreeChoise.vue'),
        },
        {
          path: '/demo/english/freedom-evaluation',
          name: 'SoeDemo_freedom',
          component: () => import('../components/demo/SoeDemo_Freedom.vue'),
        },
      ]
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: () => import('../views/Pricing.vue')
    },
    {
      path: "/feature",
      name: "Feature",
      component: () => import("../views/Feature.vue")
    },
    {
      path: "/soe",
      name: "Technology",
      component: () => import("../views/Technology.vue")
    },
    {
      path: "/contactus",
      name: "ContactUs",
      component: () => import("../views/ContactUs.vue")
    },
    {
      path: '/service-agreement',
      name: 'ServiceAgreement',
      component: () => import('../views/File.vue')
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('../views/File.vue')
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../views/BlogList.vue'),
    },
    {
      path: '/blogdetail',
      name: 'BlogDetail',
      component: () => import('../views/BlogDetail.vue'),
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('../views/Help.vue'),
    }
  ];
} else {
  routes = [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeView_Mobile.vue"),
    },
    {
      path: "/demo/english/word-evaluation",
      name: "demo",
      component: () => import("../views/Demo.vue"),
      children: [
        {
          path: '/demo/english/phoneme-evaluation',
          name: 'SoeDemo_phoneme',
          component: () => import('@/components/demo/SoeDemo_Phoneme.vue')
        },
        {
          path: '/demo/english/word-evaluation',
          name: 'SoeDemo_word',
          component: () => import('../components/demo/SoeDemo_Word.vue'),
        },
        {
          path: '/demo/english/sentence-evaluation',
          name: 'SoeDemo_sentence',
          component: () => import('../components/demo/SoeDemo_Sentence.vue'),
        },
        {
          path: '/demo/english/paragraph-evaluation',
          name: 'SoeDemo_paragraph',
          component: () => import('../components/demo/SoeDemo_Paragraph.vue'),
        },
        {
          path: '/demo/english/word-mistake-evaluation',
          name: 'SoeDemo_wordmistake',
          component: () => import('../components/demo/SoeDemo_WordMistake.vue'),
        },
        {
          path: '/demo/english/sentence-mistake-evaluation',
          name: 'SoeDemo_sentencemistake',
          component: () => import('../components/demo/SoeDemo_SentenceMistake.vue'),
        },
        {
          path: '/demo/english/oral-questions-evaluation',
          name: 'SoeDemo_oralquestions',
          component: () => import('../components/demo/SoeDemo_OralQuestions.vue'),
        },
        {
          path: '/demo/english/story-evaluation',
          name: 'SoeDemo_story',
          component: () => import('../components/demo/SoeDemo_Story.vue'),
        },
        {
          path: '/demo/english/oral-composition-evaluation',
          name: 'SoeDemo_oralcomposition',
          component: () => import('../components/demo/SoeDemo_OralComposition.vue'),
        },
        {
          path: '/demo/english/recite-evaluation',
          name: 'SoeDemo_recite',
          component: () => import('../components/demo/SoeDemo_Recite.vue'),
        },
        {
          path: '/demo/english/choice-evaluation',
          name: 'SoeDemo_choise',
          component: () => import('../components/demo/SoeDemo_Choise.vue'),
        },
        {
          path: '/demo/english/elective-evaluation',
          name: 'SoeDemo_freechoise',
          component: () => import('../components/demo/SoeDemo_FreeChoise.vue'),
        },
        {
          path: '/demo/english/freedom-evaluation',
          name: 'SoeDemo_freedom',
          component: () => import('../components/demo/SoeDemo_Freedom.vue'),
        },
      ]
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: () => import('../views/Pricing_Mobile.vue')
    },
    {
      path: "/feature",
      name: "Feature",
      component: () => import("../views/Feature_Mobile.vue")
    },
    {
      path: "/soe",
      name: "Technology",
      component: () => import("../views/Technology_Mobile.vue")
    },
    {
      path: "/contactus",
      name: "ContactUs",
      component: () => import("../views/ContactUs_Mobile.vue")
    },
    {
      path: '/service-agreement',
      name: 'ServiceAgreement',
      component: () => import('../views/File.vue')
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('../views/File.vue')
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import('../views/BlogList.vue'),
    },
    {
      path: '/blogdetail',
      name: 'BlogDetail',
      component: () => import('../views/BlogDetail.vue'),
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('../views/Help.vue'),
    }
  ];
}

const router = new VueRouter({
  mode: "history",
  base: "/",
  // mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});

router.afterEach(()=>{
  if (router.app.$route.path.indexOf("/demo") === -1) {
    window.scrollTo(0,0);
  }
})

export default router;
